import React from 'react'
import moment from 'moment-timezone'

export default (props) => {
  const {
    dateString,
    backgroundColor,
    primaryColor,
    secondaryColor,
    className,
    showTime = true,
  } = props
  const date = moment.tz(dateString, 'US/Eastern')
  return (
    <div
      className={`date-box ${className}`}
      style={{ backgroundColor: `#${backgroundColor}` }}
    >
      <span className="month" style={{ color: `#${secondaryColor}` }}>
        {date.format('MMMM')}
      </span>
      <span className="day" style={{ color: `#${primaryColor}` }}>
        {date.format('D')}
      </span>
      {showTime ? (
        <span className="start-time" style={{ color: `#${secondaryColor}` }}>
          {date.format('h:mm A z')}
        </span>
      ) : null}
    </div>
  )
}
